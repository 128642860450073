.header {
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--mantine-spacing-md);
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  padding-left: 30px;
  padding-right: 10px;
}

.logos {
  display: flex;
}

@media (max-width: 600px) {
  .logos {
    display: none;
  }
}